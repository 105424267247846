interface Options<T> {
   defaultValue?: () => T
   getter?: (value: string) => T
   setter?: (value: T) => string
}

/**
 * Use query parameter.
 */
export function useQueryParam<T>(key: string, options: Options<T> = {}) {
   const route = useRoute()
   const router = useRouter()
   const defaultValue = options.defaultValue?.() as T
   const getter = options.getter ?? defaultGetter(defaultValue)
   const setter = options.setter ?? defaultSetter(defaultValue)

   const navigate = useDebounceFn(async () => {
      await router.push({ force: true, query: route.query, replace: true })
   }, 200)

   return computed({
      get() {
         const { meta, query } = route
         const value = query[key]?.toString()
         return (!value ? meta[key] || defaultValue : getter(value)) as T
      },
      set(value) {
         const { meta, query } = route
         if (shouldRemoveKey(value, defaultValue, meta[key]))
            delete query[key]
         else query[key] = setter(value as never)
         navigate()
      },
   })
}

/**
 * Should remove key from query.
 */
function shouldRemoveKey(value: unknown, defaultValue: unknown, routeLayer?: unknown) {
   return isEqual(value, routeLayer || defaultValue) || !String(value).trim()
}

/**
 * Is equal.
 */
function isEqual(a: unknown, b: unknown) {
   return JSON.stringify(a) === JSON.stringify(b)
}

/**
 * Default getter.
 */
function defaultGetter<T>(defaultValue: T): (value: string) => T {
   if (typeof defaultValue === 'number') {
      return (value: string) => Number(value) as T
   }
   else if (typeof defaultValue === 'boolean') {
      return (value: string) => Boolean(value === 'true') as T
   }
   else if (Array.isArray(defaultValue)) {
      const getter = defaultGetter(defaultValue[0])
      return (value: string) => value.split(',').map(getter) as T
   }

   return (value: string) => value as T
}

/**
 * Default setter.
 */
function defaultSetter(defaultValue: unknown) {
   if (typeof defaultValue === 'number') {
      return (value: number) => value.toString()
   }
   else if (typeof defaultValue === 'boolean') {
      return (value: boolean) => value.toString()
   }
   else if (Array.isArray(defaultValue)) {
      return (value: unknown[]) => value.join(',')
   }

   return (value: string) => value
}
